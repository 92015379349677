"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'CLUB084',
    organizationId: 'Ykq0Z9kUnpSWSEkBnfoF',
    version: '2.0',
    ionicAppId: '06ed6a56',
    applicationKey: 'crazyvodkatonic',
    applicationType: 'standalone',
    artistId: '6Nks2ocF4UQkMKXgob0lFS3jjlz2',
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.crazyvodkatonic',
        appId: '1500231285',
    },
    android: {
        bundleId: 'com.utoniq.crazyvodkatonic',
    },
    platform: ['native'],
    custom: {
        modules: {
            tutorial: false,
            trade: false,
            membership: true,
            thread: false,
            prepaidPoint: null,
            gacha: false,
            nft: false,
            liveStream: false,
            item: true,
            store: false,
            campaign: false,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: false,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: [],
        artistTabs: ['home', 'posts', 'myProfile'],
    },
    deeplinkUrl: 'https://crazyvodkatonic.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/3306773',
    applink: 'crazyvodkatonic.utoniq.com',
    storagePath: 'crazyvodkatonic',
    dynamicLinkPrefix: 'crazyvodkatonic',
};
exports.default = appConfig;
